import { IconButton, type SxProps, Tooltip } from '@mui/material';
import { Box } from '@mui/material';
import { type Theme } from '@mui/material/styles';
import { type FC, memo, type MouseEvent, useCallback, useRef } from 'react';
import { ImLink } from 'react-icons/im';
import { toast } from 'react-toastify';

import { useModuleStyles } from '@modules/common/hooks/useModuleStyles';
import { POST_MESSAGE_EVENT_TYPE } from '@modules/types';
import { sendPostMessage } from '@utils/sendPostMessage';

interface ICopyLinkButton {
  id: string;
  tooltipTitle: string;
  withAnimation: boolean;
  sxProps?: SxProps<Theme>;
}

const CopyLinkButton: FC<ICopyLinkButton> = ({
  id,
  sxProps = {},
  tooltipTitle,
  withAnimation,
}) => {
  const buttonRef = useRef<null | HTMLButtonElement>(null);

  const animateCopyIcon = useCallback(() => {
    if (buttonRef.current) {
      buttonRef.current.classList.add('animate');
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.classList.remove('animate');
        }
      }, 300);
    }
  }, []);

  const handleCopyId = useCallback(
    (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      e.stopPropagation();
      animateCopyIcon();
      sendPostMessage(POST_MESSAGE_EVENT_TYPE.COPY_EDIT_ID, id);
      toast.success('Link is copied.');
    },
    [animateCopyIcon, id]
  );

  const { copyLinkIconStyles } = useModuleStyles();

  return (
    <Tooltip disableInteractive color="secondary" title={tooltipTitle}>
      <IconButton
        sx={{
          ...copyLinkIconStyles,
          ...sxProps,
        }}
        color="primary"
        onClick={handleCopyId}
        ref={buttonRef}
        size="medium"
      >
        <ImLink />
        {withAnimation ? <Box className="ripple" component="span" /> : null}
      </IconButton>
    </Tooltip>
  );
};

export default memo(CopyLinkButton);
