import { useCallback, useReducer } from 'react';

export interface IModalState<K> {
  data: K;
  opened: boolean;
}

interface IModalHandlers<K> {
  closeModal: () => void;
  openModal: (data: Partial<K>) => void;
}

type ModalAction<K> =
  | { type: 'CLOSE_MODAL' }
  | { payload: K; type: 'OPEN_MODAL' };

function modalReducer<K>(
  state: IModalState<K>,
  action: ModalAction<K>
): IModalState<K> {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { ...state, opened: true, data: action.payload };
    case 'CLOSE_MODAL':
      return { opened: false, data: {} as unknown as K };
    default:
      throw new Error(`Unknown action type`);
  }
}

export function useCreateModalState<K>(
  initialState: IModalState<K> = {
    opened: false,
    data: {} as unknown as K,
  }
): [IModalState<K>, IModalHandlers<K>] {
  const [state, dispatch] = useReducer(modalReducer<K>, initialState);

  const openModal = useCallback((data: Partial<K>) => {
    dispatch({ payload: data as K, type: 'OPEN_MODAL' });
  }, []);

  const closeModal = useCallback(() => {
    dispatch({ type: 'CLOSE_MODAL' });
  }, []);

  return [state, { openModal, closeModal }];
}
