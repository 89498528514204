import { useEffect, useState } from 'react';

import { useAppSelector } from '@hooks';
import { formattedDate } from '@utils/date';

export const useClock = () => {
  const [clock, setClock] = useState(new Date());
  const timezone = useAppSelector((state) => state.appConfig.config.timezone);

  useEffect(() => {
    const id = setInterval(() => {
      setClock(new Date());
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  return formattedDate(clock, { timezone });
};
