import { useEffect } from 'react';

import { useAppDispatch } from '@hooks/redux';

import { setAppConfig } from '@features/appConfig/appConfigSlice';

interface IUsePlatformTheme {
  useInIframeOnly?: boolean;
}

export const usePlatformTheme = ({
  useInIframeOnly,
}: IUsePlatformTheme = {}) => {
  const dispatch = useAppDispatch();
  const isIframe = window.self !== window.top;

  useEffect(() => {
    const shouldUsePlatformTheme =
      (useInIframeOnly && isIframe) || !useInIframeOnly;

    if (shouldUsePlatformTheme) {
      dispatch(setAppConfig({ presetColor: 'Platform' }));
    }

    return () => {
      dispatch(setAppConfig({ presetColor: 'Orange' }));
    };
  }, [dispatch, isIframe, useInIframeOnly]);
};
