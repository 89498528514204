import debounce from 'lodash/debounce';
import { useLayoutEffect, useRef } from 'react';

import { IFRAME } from '@modules/types';
import { sendPostMessage } from '@utils/sendPostMessage';

export const useIframeHeight = (iframe: IFRAME, gap: number = 4) => {
  const isIframe = window.self !== window.top;
  const previousHeight = useRef<number>(0);

  const debouncedSendHeight = debounce((height: number) => {
    if (height !== previousHeight.current) {
      previousHeight.current = height;
      sendPostMessage(`${iframe}_IFRAME_HEIGHT`, height + gap, 'height');
    }
  }, 100);

  useLayoutEffect(() => {
    if (!isIframe) {
      return;
    }

    const initialHeight = document.body.scrollHeight;
    debouncedSendHeight(initialHeight);

    const observer = new ResizeObserver(() => {
      const currentHeight = document.body.scrollHeight;
      debouncedSendHeight(currentHeight);
    });

    observer.observe(document.body);

    return () => {
      observer.disconnect();
      debouncedSendHeight.cancel();
    };
  }, [iframe, isIframe, debouncedSendHeight]);
};
