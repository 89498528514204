import { type SortingState } from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';

import { type ISearchParams, OPERATOR_SORT } from '@features/filters/types';

export const convertSortingToSearchPayload = (
  data: SortingState,
  defaultSort?: ISearchParams['sorts']
): ISearchParams['sorts'] => {
  if (isEmpty(data)) {
    return defaultSort ?? [];
  }
  const sorting = data.map((sort) => ({
    order: sort.desc ? OPERATOR_SORT.DESC : OPERATOR_SORT.ASC,
    field: sort.id.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`),
  }));

  return sorting;
};
