import { type ISortParams, OPERATOR_SORT } from '@features/filters/types';

export const convertSortingPayloadToTableValue = (data: ISortParams[]) => {
  const sorting = data.map((sort) => ({
    id: sort.field.replace(/_([a-z])/g, (match, p1) => p1.toUpperCase()),
    desc: sort.order === OPERATOR_SORT.DESC,
  }));

  return sorting;
};
