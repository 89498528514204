import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const useBreakPoints = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktopScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isLargeDesktopScreen = useMediaQuery(theme.breakpoints.down(1700));

  return {
    isMobileScreen,
    isTabletScreen,
    isDesktopScreen,
    isLargeDesktopScreen,
  };
};
