import { alpha, useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

export const usePlatformStyles = () => {
  const theme = useTheme();

  const commonLabelStyles = useMemo(
    () => ({
      '& .MuiInputLabel-root': {
        lineHeight: '1.375rem',
        color: theme.palette.primary.darker,
        '&.Mui-focused': {
          color: theme.palette.primary.darker,
        },
        '&.Mui-error, &.Mui-error.Mui-focused': {
          color: theme.palette.error.main,
        },
      },
    }),
    [theme.palette.error.main, theme.palette.primary.darker]
  );

  const commonInputStyles = useMemo(
    () => ({
      minHeight: '2.5rem',
      borderRadius: '0.625rem',
      background: theme.palette.primary.contrastBackground,
    }),
    [theme.palette.primary.contrastBackground]
  );

  const primaryButtonStyles = useMemo(
    () => ({
      padding: '0.5rem 0.8rem',
      borderRadius: '0.625rem',
      color: theme.palette.grey[0],
      background: theme.palette.success.main,
      '&:hover': {
        background: theme.palette.success.main,
        boxShadow: theme.customShadows.successButton,
      },
    }),
    [
      theme.palette.success,
      theme.customShadows.successButton,
      theme.palette.grey,
    ]
  );

  const secondaryButtonStyles = useMemo(
    () => ({
      padding: '0.5rem 0.8rem',
      borderRadius: '0.625rem',
      color: theme.palette.grey[0],
      '&:hover': {
        background: theme.palette.secondary.main,
        boxShadow: theme.customShadows.secondaryButton,
      },
    }),
    [
      theme.palette.secondary,
      theme.customShadows.secondaryButton,
      theme.palette.grey,
    ]
  );

  const commonInputBorderStyles = useMemo(
    () => ({
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.primary.darker, 0.4),
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.primary.darker, 0.4),
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `.0625rem solid ${alpha(theme.palette.primary.darker, 0.4)}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline, &.Mui-error:hover .MuiOutlinedInput-notchedOutline, &.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline':
        {
          borderColor: `${theme.palette.error.main}`,
        },
    }),
    [theme.palette.primary.darker, theme.palette.error.main]
  );

  const inputStyles = useMemo(
    () => ({
      ...commonLabelStyles,
      '& .MuiOutlinedInput-root, .MuiInputBase-root': {
        ...commonInputStyles,
        ...commonInputBorderStyles,
      },
    }),
    [commonInputBorderStyles, commonInputStyles, commonLabelStyles]
  );

  const autocompleteStyles = useMemo(
    () => ({
      ...commonLabelStyles,
      '& .MuiAutocomplete-endAdornment': {
        top: '50%',
      },
      '& .MuiOutlinedInput-root': {
        paddingTop: '0.625rem',
        ...commonInputStyles,
        ...commonInputBorderStyles,
      },
      '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
        paddingTop: '0.375rem',
        paddingLeft: '0.875rem',
        paddingRight: '3.125rem !important',
        '& input': {
          padding: '0 !important',
        },
      },
      '& .MuiChip-root': {
        width: 'auto',
        background: 'transparent',
        justifyContent: 'flex-start',
        '&:hover': {
          background: 'transparent',
        },
        '& span': {
          paddingInline: '0 0.375rem',
        },
      },
      '& .MuiAutocomplete-endAdornment button': {
        color: 'inherit',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&.MuiAutocomplete-clearIndicator': {
          padding: '0',
          width: '1rem',
          height: '1rem',
        },
      },
    }),
    [commonInputBorderStyles, commonInputStyles, commonLabelStyles]
  );

  const autocompleteListBoxStyles = useMemo(
    () => ({
      '& .MuiAutocomplete-option': {
        color: theme.palette.primary.darker,
        borderBottom: `1px solid ${alpha(theme.palette.primary.darker, 0.1)}`,
        '&:hover': {
          backgroundColor: theme.palette.info.darker,
        },
        '&[aria-selected="true"]:hover': {
          backgroundColor: theme.palette.info.lighter,
        },
        '&[aria-selected="true"].Mui-focused': {
          backgroundColor: theme.palette.info.lighter,
        },
        '&[aria-selected="true"]': {
          backgroundColor: theme.palette.info.lighter,
          '& p': {
            fontWeight: '700',
          },
        },
      },
    }),
    [
      theme.palette.info.darker,
      theme.palette.info.lighter,
      theme.palette.primary.darker,
    ]
  );

  const selectLabelStyles = useMemo(
    () => ({
      lineHeight: '1.375rem',
      color: theme.palette.primary.darker,
      '&.Mui-focused': {
        color: theme.palette.primary.darker,
      },
      '&.Mui-error, &.Mui-error.Mui-focused': {
        color: theme.palette.error.main,
      },
    }),
    [theme.palette.error.main, theme.palette.primary.darker]
  );

  const selectStyles = useMemo(
    () => ({
      ...commonInputStyles,
      '&.MuiOutlinedInput-root': {
        ...commonInputBorderStyles,
      },
      '& .MuiSelect-icon': {
        fontSize: '1rem',
        color: 'inherit',
      },
    }),
    [commonInputBorderStyles, commonInputStyles]
  );

  const selectListStyles = useMemo(
    () => ({
      color: theme.palette.primary.darker,
      borderBottom: `1px solid ${alpha(theme.palette.primary.darker, 0.1)}`,
      '&:hover': {
        backgroundColor: theme.palette.info.darker,
      },
      '&[aria-selected="true"]:hover': {
        backgroundColor: theme.palette.info.lighter,
      },
      '&[aria-selected="true"].Mui-focused': {
        backgroundColor: theme.palette.info.lighter,
      },
      '&[aria-selected="true"]': {
        fontWeight: '700',
        backgroundColor: theme.palette.info.lighter,
      },
    }),
    [
      theme.palette.info.darker,
      theme.palette.info.lighter,
      theme.palette.primary.darker,
    ]
  );

  const dateTimePickerStyles = useMemo(
    () => ({
      ...commonLabelStyles,
      '& .MuiOutlinedInput-root': {
        ...commonInputStyles,
        ...commonInputBorderStyles,
      },
      '& .MuiInputAdornment-root button': {
        '& .MuiSvgIcon-root': { color: theme.palette.primary.darker },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    }),
    [
      commonInputBorderStyles,
      commonInputStyles,
      commonLabelStyles,
      theme.palette.primary.darker,
    ]
  );
  const dateTimeCalendarStyles = useMemo(
    () => ({
      '& .MuiDialogActions-root .MuiButtonBase-root.MuiButton-root': {
        color: theme.palette.info.main,
      },
      '& .MuiDateCalendar-root .MuiPickersDay-root.MuiPickersDay-today:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiDialogActions-root .MuiButtonBase-root.MuiButton-root:hover': {
        boxShadow: 'none',
        background: alpha(theme.palette.info.main, 0.08),
      },
      '& .MuiMultiSectionDigitalClock-root .MuiMenuItem-root.Mui-selected': {
        color: theme.palette.common.white,
        background: theme.palette.success.light,
      },
      '& .MuiDateCalendar-root .MuiPickersDay-root.MuiPickersDay-today': {
        background: 'transparent',
        color: theme.palette.success.light,
        borderColor: theme.palette.success.light,
      },
      '& .MuiDateCalendar-root .MuiPickersDay-root.Mui-selected': {
        color: theme.palette.common.white,
        borderColor: theme.palette.success.light,
        backgroundColor: theme.palette.success.light,
        '&:hover': {
          backgroundColor: theme.palette.success.light,
        },
      },
      '& .MuiDateCalendar-root .MuiPickersYear-yearButton.Mui-selected': {
        color: theme.palette.common.white,
        borderColor: theme.palette.success.light,
        backgroundColor: theme.palette.success.light,
        '&:hover': {
          backgroundColor: theme.palette.success.light,
        },
      },
    }),
    [
      theme.palette.common.white,
      theme.palette.info.main,
      theme.palette.success.light,
    ]
  );

  const checkboxStyles = useMemo(
    () => ({
      '&:hover': {
        background: 'none',
        '&.size-small .icon': {
          borderColor: theme.palette.primary.main,
        },
      },
      '&.Mui-checked': {
        '&.size-small .icon': {
          borderColor: theme.palette.success.main,
          '& .filled': {
            borderColor: 'transparent',
          },
        },
      },
      '&.size-small .icon': {
        width: '1.125rem',
        height: '1.125rem',
        borderRadius: '0.125rem',
        border: `2px solid ${theme.palette.grey['300']}`,
        background: theme.palette.primary.contrastBackground,
        '& .filled': {
          fontSize: '1.25rem',
          fill: theme.palette.success.main,
        },
      },
    }),
    [
      theme.palette.grey,
      theme.palette.primary.main,
      theme.palette.success.main,
      theme.palette.primary.contrastBackground,
    ]
  );

  const tableStyles = useMemo(
    () => ({
      thead: {
        height: '3rem',
        border: 'none',
        backgroundColor: 'transparent',
        '& tr th': {
          fontWeight: '600',
          paddingLeft: '1rem',
          textTransform: 'capitalize',
          '& svg': {
            cursor: 'pointer',
          },
          '&:first-of-type': {
            paddingLeft: '1.688rem !important',
          },
        },
      },
      tbody: {
        backgroundColor: 'transparent',
        '& tr': {
          background: 'none',
          borderLeft: '.5rem solid transparent',
          borderRight: '.5rem solid transparent',
          '&:last-of-type': {
            '& td:first-of-type': {
              '& .customCellWrapper': {
                borderBottomLeftRadius: '.625rem',
              },
            },
            '& td:last-of-type': {
              '& .customCellWrapper': {
                borderBottomRightRadius: '.625rem',
              },
            },
          },
          '&:first-of-type': {
            '& .customCellWrapper': {
              paddingTop: '.375rem',
            },
            '& td:first-of-type': {
              '& .customCellWrapper': {
                borderTopLeftRadius: '.625rem',
              },
            },
            '& td:last-of-type': {
              '& .customCellWrapper': {
                borderTopRightRadius: '.625rem',
              },
            },
          },
          '&:hover': {
            backgroundColor: 'transparent',
            '& td': {
              '& .customCellWrapper': {
                '& .customCell': {
                  boxShadow: `inset 0 1px 0 ${theme.palette.info.A700}, inset 0 -1px 0 ${theme.palette.info.A700}`,
                },
              },
              '&:first-of-type': {
                '& .customCellWrapper': {
                  '& .customCell': {
                    boxShadow: `inset 1px 0 0 ${theme.palette.info.A700}, inset 0 1px 0 ${theme.palette.info.A700}, inset 0 -1px 0 ${theme.palette.info.A700}`,
                  },
                },
              },
              '&:last-of-type': {
                '& .customCellWrapper': {
                  '& .customCell': {
                    boxShadow: `inset -1px 0 0 ${theme.palette.info.A700}, inset 0 1px 0 ${theme.palette.info.A700}, inset 0 -1px 0 ${theme.palette.info.A700}`,
                  },
                },
              },
            },
          },
          '& td': {
            padding: '0',
            border: 'none',
            borderRight: `1px solid ${alpha(theme.palette.primary.darker, 0.25)}`,
            '&:last-of-type': {
              borderRight: 'none',
              paddingRight: '0 !important',
              '& .customCellWrapper': {
                paddingRight: '.375rem',
                '& .customCell': {
                  borderTopRightRadius: '.625rem',
                  borderBottomRightRadius: '.625rem',
                },
              },
            },
            '&:first-of-type': {
              paddingLeft: '0 !important',
              '& .customCellWrapper': {
                paddingLeft: '.375rem',
                '& .customCell': {
                  position: 'relative',
                  borderTopLeftRadius: '.625rem',
                  borderBottomLeftRadius: '.625rem',
                },
              },
            },
            '& .customCellWrapper': {
              width: '100%',
              paddingBottom: '.375rem',
              boxShadow: theme.customShadows.tableBody,
              '& .customCell': {
                width: '100%',
                height: '3rem',
                display: 'flex',
                alignItems: 'center',
                padding: '.125rem 1rem',
                verticalAlign: 'middle',
                background: theme.palette.primary.contrastBackground,
              },
            },
          },
        },
      },
    }),
    [
      theme.customShadows.tableBody,
      theme.palette.info.A700,
      theme.palette.primary.contrastBackground,
      theme.palette.primary.darker,
    ]
  );

  return {
    inputStyles,
    tableStyles,
    selectStyles,
    checkboxStyles,
    selectListStyles,
    selectLabelStyles,
    autocompleteStyles,
    primaryButtonStyles,
    dateTimePickerStyles,
    secondaryButtonStyles,
    dateTimeCalendarStyles,
    autocompleteListBoxStyles,
  };
};
