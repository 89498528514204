import { useCallback, useMemo } from 'react';

import {
  useGetCountriesQuery,
  useGetCurrenciesQuery,
  useGetEnumsQuery,
  useGetLocalesQuery,
  useGetTimeZonesQuery,
} from '@api/systemValuesApiSlice';
import { type SystemOptionValue } from '@api/types';
import { type Option } from '@shared/form/types';

type Value = number | string;

export interface IGetOptions {
  key: SystemOptionValue;
  withDisabled?: boolean;
  allowedValues?: Value[];
  disabledValues?: Value[];
}

export interface IGetLabel {
  key: SystemOptionValue;
  value: number | string;
}

interface IUseCatalog {
  isLoading: boolean;
  localeOptions: Option[];
  countryOptions: Option[];
  currencyOptions: Option[];
  timeZoneOptions: Option[];
  getLabel: (data: IGetLabel) => string;
  getOptions: (data: IGetOptions) => Option[];
}

const POLLING_INTERVAL: number = 300000;

export const useCatalog = (): IUseCatalog => {
  const { data: catalogEnums, isLoading: isLoadingEnums } = useGetEnumsQuery(
    '',
    { pollingInterval: POLLING_INTERVAL }
  );
  const { data: timeZoneOptions = [], isLoading: isLoadingTimeZones } =
    useGetTimeZonesQuery('', { pollingInterval: POLLING_INTERVAL });
  const { data: countryOptions = [], isLoading: isLoadingCountries } =
    useGetCountriesQuery('', { pollingInterval: POLLING_INTERVAL });
  const { data: currencyOptions = [], isLoading: isLoadingCurrencies } =
    useGetCurrenciesQuery('', { pollingInterval: POLLING_INTERVAL });
  const { data: localeOptions = [], isLoading: isLoadingLocales } =
    useGetLocalesQuery('', { pollingInterval: POLLING_INTERVAL });

  const getOptions = useCallback(
    ({
      key,
      allowedValues,
      disabledValues,
      withDisabled = true,
    }: IGetOptions): Option[] => {
      if (catalogEnums) {
        if (allowedValues && allowedValues.length > 0) {
          return withDisabled
            ? catalogEnums[key].map((option) => {
                if (!allowedValues.includes(option.value)) {
                  return { ...option, disabled: true };
                } else {
                  return option;
                }
              })
            : catalogEnums[key]?.filter((option) =>
                allowedValues.includes(option.value)
              );
        } else if (disabledValues && disabledValues.length > 0) {
          return withDisabled
            ? catalogEnums[key].map((option) => {
                if (disabledValues.includes(option.value)) {
                  return { ...option, disabled: true };
                } else {
                  return option;
                }
              })
            : catalogEnums[key]?.filter((option) =>
                disabledValues.includes(option.value)
              );
        } else {
          return catalogEnums[key];
        }
      }

      return [];
    },
    [catalogEnums]
  );

  const getLabel = useCallback(
    ({ key, value }: IGetLabel): string =>
      catalogEnums
        ? (catalogEnums[key]?.find((option) => option.value === value)?.label ??
          '')
        : '',
    [catalogEnums]
  );

  const isLoading = useMemo(
    () =>
      isLoadingEnums ||
      isLoadingTimeZones ||
      isLoadingCountries ||
      isLoadingCurrencies ||
      isLoadingLocales,
    [
      isLoadingEnums,
      isLoadingTimeZones,
      isLoadingCountries,
      isLoadingCurrencies,
      isLoadingLocales,
    ]
  );

  return {
    getLabel,
    isLoading,
    getOptions,
    localeOptions,
    countryOptions,
    timeZoneOptions,
    currencyOptions,
  };
};
