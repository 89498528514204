import { Checkbox, Popper, type PopperProps } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  createContext,
  forwardRef,
  type ForwardRefExoticComponent,
  type HTMLAttributes,
  type MouseEvent,
  type ReactElement,
  type RefAttributes,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { type ListChildComponentProps, VariableSizeList } from 'react-window';

import { type StyledComponent } from '@emotion/styled';

const LIST_PADDING = 8;

interface IRenderRow {
  props: ListChildComponentProps;
}

export const useSelectVirtualization = ({
  listWithCheckBox,
}: {
  listWithCheckBox: boolean;
}): {
  StyledPopper: StyledComponent<PopperProps>;
  ListComponent: ForwardRefExoticComponent<
    HTMLAttributes<HTMLElement> & RefAttributes<HTMLDivElement>
  >;
} => {
  const ref = useRef<VariableSizeList>(null);

  const renderRow = useCallback(
    ({ props }: IRenderRow) => {
      const { data, index, style } = props;
      const dataSet = data[index];
      const inlineStyle = {
        ...style,
        top: (style.top as number) + LIST_PADDING,
      };

      if (Object.hasOwn(dataSet, 'group')) {
        return (
          <ListSubheader component="div" key={dataSet.key} style={inlineStyle}>
            {dataSet.group}
          </ListSubheader>
        );
      }

      return (
        <Typography
          noWrap
          component="li"
          key={dataSet.key}
          style={inlineStyle}
          {...dataSet.props}
          onClick={(e: MouseEvent<HTMLElement>) => {
            dataSet.props.onClick(e);
          }}
        >
          {listWithCheckBox ? (
            <>
              <Checkbox
                checked={dataSet.props?.children[0]?.props?.checked}
                style={{ marginRight: 8 }}
              />
              {dataSet.props?.children[1]}
            </>
          ) : (
            dataSet.props?.children[1]
          )}
        </Typography>
      );
    },
    [listWithCheckBox]
  );

  const OuterElementContext = createContext({});

  const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext);

    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data: number) {
    useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  const ListComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
    function ListComponent(props, ref) {
      const { children, ...other } = props;
      const itemData: ReactElement[] = [];
      (children as ReactElement[]).forEach(
        (item: { children?: ReactElement[] } & ReactElement) => {
          itemData.push(item);
          itemData.push(...(item.children ?? []));
        }
      );

      const theme = useTheme();
      const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
      const itemCount = itemData.length;
      const itemSize = matchesUpSm ? 36 : 48;

      const gridRef = useResetCache(itemCount);

      const getChildSize = (child: ReactElement): number => {
        if (Object.hasOwn(child, 'group')) {
          return 48;
        }

        return itemSize;
      };

      const getHeight = (): number => {
        if (itemCount > 8) {
          return 8 * itemSize;
        }

        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
      };

      return (
        <div ref={ref}>
          <OuterElementContext.Provider value={other}>
            <VariableSizeList
              estimatedItemSize={itemSize}
              height={getHeight() + 2 * LIST_PADDING}
              innerElementType="ul"
              itemCount={itemCount}
              itemData={itemData}
              itemSize={(index: number) => getChildSize(itemData[index])}
              outerElementType={OuterElementType}
              overscanCount={5}
              ref={gridRef}
              width="100%"
            >
              {(props) => renderRow({ props })}
            </VariableSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    }
  );

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      '& ul': {
        margin: 0,
        padding: 0,
      },
    },
  });

  // Prevent scroll jumping in virtualized selects !!!
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ StyledPopper, ListComponent }), []);
};
