import { SYSTEM_ROLES } from '@constants';
import { usePermissions } from '@hooks';
import TitleWithCopy from '@modules/common/TitleWithCopy';
import { MODULE } from '@modules/types';
import { FEATURE_NAME, getFeatureToggle } from '@utils/getFeatureToggle';

const { BONUS, CODE_CAMPAIGN, AUTOMATIC_CAMPAIGN, SCHEDULED_CAMPAIGN } = MODULE;

interface IUseModeTitle {
  id: string;
  isEditMode: boolean;
  hasBrandsWithoutAccess: boolean;
  module: Extract<
    MODULE,
    'bonus' | 'codeCampaign' | 'automaticCampaign' | 'scheduledCampaign'
  >;
}

const permissions: Record<
  Extract<
    MODULE,
    'bonus' | 'codeCampaign' | 'automaticCampaign' | 'scheduledCampaign'
  >,
  SYSTEM_ROLES.PermissionModules
> = {
  [BONUS]: 'ext_bonus_system_bonuses',
  [CODE_CAMPAIGN]: 'bonus_system_code_campaigns',
  [AUTOMATIC_CAMPAIGN]: 'ext_bonus_system_automatic_campaigns',
  [SCHEDULED_CAMPAIGN]: 'ext_bonus_system_scheduled_campaigns',
};

export const useModeTitle = ({
  id,
  module,
  isEditMode,
  hasBrandsWithoutAccess,
}: IUseModeTitle) => {
  const isIframe = window.self !== window.top;

  const isCopyCodeCampaignEnabled = getFeatureToggle(
    FEATURE_NAME.COPY_CODE_CAMPAIGN_LINK
  );

  const isCampaignPage = [
    AUTOMATIC_CAMPAIGN,
    CODE_CAMPAIGN,
    SCHEDULED_CAMPAIGN,
  ].includes(module);

  const modulePermissions = usePermissions(permissions[module]);

  const getTitle = (action: 'Edit' | 'View' | 'Create') =>
    `${action} ${isCampaignPage ? 'Campaign' : 'Bonus'}`;

  const getTitleWithCopy = (action: 'Edit' | 'View') => (
    <TitleWithCopy
      id={id}
      title={getTitle(action)}
      tooltipTitle={`Copy link to ${isCampaignPage ? 'campaign' : 'bonus template'}`}
    />
  );

  if (!isEditMode) {
    return getTitle('Create');
  }

  const isCopyDisabled =
    !isIframe || (module === CODE_CAMPAIGN && !isCopyCodeCampaignEnabled);

  if (!modulePermissions?.hasEditPermission || hasBrandsWithoutAccess) {
    return isCopyDisabled ? getTitle('View') : getTitleWithCopy('View');
  }

  return isCopyDisabled ? getTitle('Edit') : getTitleWithCopy('Edit');
};
