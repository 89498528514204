import { toast } from 'react-toastify';
import { useEffectOnce } from 'react-use';

import * as Sentry from '@sentry/react';

import { setAppConfig } from '@features/appConfig/appConfigSlice';
import { useAppDispatch } from '@hooks';
import { isValidTimezone } from '@utils/isValidTimezone';
import { parseSearchParams } from '@utils/parseUrlParams';

const getErrorMessage = (invalidTimezone?: string) =>
  invalidTimezone
    ? `Invalid time zone specified: ${invalidTimezone}`
    : 'Invalid organization timezone: all dates will be displayed in UTC';

export const useTimezone = () => {
  const dispatch = useAppDispatch();

  const queryParamTimezone = parseSearchParams(
    window.location.search,
    'timezone'
  );

  useEffectOnce(() => {
    if (!queryParamTimezone) {
      return;
    }

    if (isValidTimezone(queryParamTimezone)) {
      dispatch(
        setAppConfig({
          timezone: queryParamTimezone,
        })
      );
    } else {
      toast.error(getErrorMessage());
      Sentry.captureException(getErrorMessage(queryParamTimezone));
      console.error(getErrorMessage(queryParamTimezone));
    }
  });
};
