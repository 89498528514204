import { alpha, SxProps, Theme, useTheme } from '@mui/material';

export const useModuleStyles = () => {
  const theme = useTheme();

  const additionalInfoTextStyles = {
    gap: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  };

  const additionalInfoExportSectionStyles = {
    gap: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  };

  const copyLinkIconStyles: SxProps<Theme> = {
    marginLeft: '0.5rem',
    position: 'relative',
    paddingTop: '0.613rem',
    display: 'inline-block',
    '&.animate .ripple': {
      animation: 'ripple-effect 0.6s ease-out',
    },
    '@keyframes ripple-effect': {
      '0%': {
        opacity: 1,
        transform: 'scale(0)',
      },
      '100%': {
        opacity: 0,
        transform: 'scale(1)',
      },
    },
    '& .ripple': {
      opacity: 0,
      width: '140%',
      height: '140%',
      top: '-0.25rem',
      left: '-0.5rem',
      borderRadius: '50%',
      position: 'absolute',
      transform: 'scale(0)',
      pointerEvents: 'none',
      backgroundColor: `${alpha(theme.palette.primary.main, 0.4)}`,
    },
  };

  const infoComponentIconDefaultStyles: SxProps<Theme> = {
    height: '1rem',
    margin: '0.5rem',
    cursor: 'default',
  };

  const languageSelectionFieldStyles = {
    marginTop: '0.5rem',
    marginBottom: '1rem',
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
  };

  const externalDescriptionInputStyles = {
    minHeight: '5.3rem',
    '& textarea': {
      height: '5.3rem !important',
      overflowY: 'auto !important',
    },
  };

  return {
    copyLinkIconStyles,
    additionalInfoTextStyles,
    languageSelectionFieldStyles,
    infoComponentIconDefaultStyles,
    externalDescriptionInputStyles,
    additionalInfoExportSectionStyles,
  };
};
